












































import { Component, Vue } from "vue-property-decorator";
import { UserSessionManager } from "kogitte";
import { InputComponent } from "keiryo";
import { ValidationObserver } from "vee-validate";
import Notification from "@/common/Notification";
import TranslationUtils from "@/common/utilities/TranslationUtils";
import GlobalEventBus from "@/common/GlobalEventBus";
import { CaptchaComponent } from "keiryo";
import UserDataContext from "@/dataContexts/UserDataContext";
import CurrentUser from "@/common/CurrentUser";
import Settings from "@/Settings";
import Router from "@/router/Router";
import Routes from "@/router/Routes";
import GlobalEvents from "@/constants/GlobalEvents";

@Component({
    components: {
        InputComponent,
        CaptchaComponent
    }
})
export default class LoginView extends Vue {
    private sessionManager: UserSessionManager = new UserSessionManager();
    private userDataContext: UserDataContext = new UserDataContext();

    private loading = false;

    private username = "";
    private password = "";
    private captchaResponse = "";

    private siteKey = Settings.HCaptcha.SiteKey;

    private async onSubmit() {
        const observer = this.$refs.observer as InstanceType<typeof ValidationObserver>;

        if (this.captchaResponse && await observer.validate()) {
            this.loading = true;

            const authResult = await this.sessionManager.login(this.username, this.password, this.captchaResponse);

            if (authResult.successfully && authResult.data) {
                await CurrentUser.loadUserInfo();

                GlobalEventBus.$emit(GlobalEvents.UpdateLoginState, "login");
                Router.navigate(Routes.Home);
            } else {
                Notification.addError(TranslationUtils.translate("view.login.wrongLoginData")).show();
            }

            this.loading = false;
        }
    }
}
